export default function Profile(attrs) {
  this.first_name = attrs.first_name ? attrs.first_name : null;
  this.last_name = attrs.last_name ? attrs.last_name : null;
  this.third_name = attrs.third_name ? attrs.third_name : null;
  this.email = attrs.email ? attrs.email : null;
  this.phone = attrs.phone ? formatPhoneNumber(attrs.phone) : null;
  this.birthdate = attrs.birthdate ? attrs.birthdate : null;
  this.city = attrs.city ? attrs.city : null;
  this.about = attrs.about ? attrs.about : null;
  this.education = attrs.education ? attrs.education : null;
  this.experience = attrs.experience ? attrs.experience : null;
  this.skills = attrs.skills ? attrs.skills : null;
  this.telegram = attrs.telegram ? attrs.telegram : null;
  this.instagram = attrs.instagram ? attrs.instagram : null;
  this.avatar = attrs.avatar ? attrs.avatar : null;
}

function formatPhoneNumber(phone) {
  let cleaned = ("" + phone).replace(/\D/g, "");
  let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return (
      "+" +
      match[1] +
      " (" +
      match[2] +
      ") " +
      match[3] +
      " " +
      match[4] +
      "-" +
      match[5]
    );
  }
  return null;
}
